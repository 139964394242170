import * as React from "react";
import { Button, Heading, Input, Page, PageSection } from "../components";

const Recaptcha = require("react-recaptcha");

interface State {
  emailAddress: string;
  isEmailAddressRequired: boolean;
  phoneNumber: string;
  isPhoneNumberRequired: boolean;
  isCaptchaVerified: boolean;
  captchaToken: string;
}

class RequestProposalForm extends React.Component<
  { pageContext: { GATSBY_HMCMGT_API_GATEWAY_URL: string } },
  State
> {
  public constructor(props) {
    super(props);

    this.state = {
      emailAddress: "",
      isEmailAddressRequired: true,
      phoneNumber: "",
      isPhoneNumberRequired: true,
      isCaptchaVerified: false,
      captchaToken: ""
    };
  }

  private updateRequiredFields: () => void = () => {
    const { emailAddress, phoneNumber } = this.state;
    const nextState = { ...this.state };

    if (!!emailAddress == !!phoneNumber) {
      nextState.isEmailAddressRequired = true;
      nextState.isPhoneNumberRequired = true;
    } else if (!emailAddress && phoneNumber) {
      nextState.isEmailAddressRequired = false;
      nextState.isPhoneNumberRequired = true;
    } else if (emailAddress && !phoneNumber) {
      nextState.isEmailAddressRequired = true;
      nextState.isPhoneNumberRequired = false;
    }

    this.setState(nextState);
  };

  private handlePhoneNumberChange: (
    event: React.ChangeEvent<HTMLInputElement>
  ) => void = event => {
    this.setState(
      {
        phoneNumber: event.target.value
      },
      this.updateRequiredFields
    );
  };

  private handleEmailAddressChange: (
    event: React.ChangeEvent<HTMLInputElement>
  ) => void = event => {
    this.setState(
      { emailAddress: event.target.value },
      this.updateRequiredFields
    );
  };

  private recaptchaVerifyCallback: (response: string) => void = response => {
    this.setState({ captchaToken: response, isCaptchaVerified: true });
  };

  /* istanbul ignore next */
  // It's difficult to verify the expiration callback in tests
  private recaptchaExpiredCallback = () => {
    this.setState({ captchaToken: "", isCaptchaVerified: false });
  };

  public render() {
    const { GATSBY_HMCMGT_API_GATEWAY_URL } = this.props.pageContext;
    const { isCaptchaVerified, captchaToken } = this.state;
    return (
      <Page title="Request a Proposal" layout="default">
        <PageSection>
          <form
            method="POST"
            action={`${GATSBY_HMCMGT_API_GATEWAY_URL}/proposal`}
          >
            <Heading style={{ textAlign: "center" }} level={1}>
              Request a Proposal
            </Heading>
            <p style={{ textAlign: "center" }}>
              {`Your request will immediately be sent to the appropriate Hawaiiana staff, who will contact you once they've had an opportunity to review your information. We're looking forward to assisting you!`}
            </p>
            <hr />
            <fieldset>
              <legend>Property Details</legend>

              <div>
                <label htmlFor="community_name">Community Name</label>
                <Input id="community_name" name="community_name" required />
              </div>

              <div>
                <label htmlFor="community_message">Message</label>
                <Input
                  type="textarea"
                  id="community_message"
                  name="community_message"
                  required
                />
              </div>

              <legend>Contact Information</legend>

              <div className="input--split">
                <label htmlFor="first_name">First Name</label>
                <Input
                  id="first_name"
                  name="first_name"
                  className="input--split"
                  required
                />
              </div>
              <div className="input--split">
                <label htmlFor="last_name">Last Name</label>
                <Input
                  id="last_name"
                  name="last_name"
                  className="input--split"
                  required
                />
              </div>

              <fieldset>
                <div>
                  <label htmlFor="phone_number">Phone Number</label>
                  <Input
                    id="phone_number"
                    name="phone_number"
                    type="tel"
                    value={this.state.phoneNumber}
                    required={this.state.isPhoneNumberRequired}
                    onChange={this.handlePhoneNumberChange}
                  />
                </div>

                <div>
                  <label htmlFor="email">Email Address</label>
                  <Input
                    id="email"
                    name="email_address"
                    type="email"
                    value={this.state.emailAddress}
                    required={this.state.isEmailAddressRequired}
                    onChange={this.handleEmailAddressChange}
                  />
                </div>
              </fieldset>

              <div>
                <label htmlFor="message">Comments</label>
                <Input type="textarea" id="message" name="message" required />
              </div>
            </fieldset>
            <div id="recaptcha-container">
              {typeof window !== "undefined" && (
                <Recaptcha
                  elementId="recaptcha-container"
                  render="explicit"
                  sitekey={process.env.GATSBY_GOOGLE_RECAPTCHA_KEY}
                  verifyCallback={this.recaptchaVerifyCallback}
                  onloadCallback={console.debug}
                  expiredCallback={this.recaptchaExpiredCallback}
                />
              )}
            </div>
            <Input
              id="captcha_token"
              name="captcha_token"
              type="hidden"
              value={captchaToken}
              required
            />
            {isCaptchaVerified && (
              <p style={{ textAlign: "center" }}>
                <Button type="submit">Submit</Button>
              </p>
            )}
          </form>
        </PageSection>
      </Page>
    );
  }
}

export default RequestProposalForm;
